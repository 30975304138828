<template>
  <div
    v-if="menuLength"
    class="bs-controller br-5 mb-26"
  >
    <div
      v-if="!simpleMode && showUpgrade"
      class="d-flex justify-between align-center bg-form px-12 py-12 br-top-left-5 br-top-right-5"
    >
      <div>
        <span class="text-link color-text-lighter uppercase">{{ menuLength }}/{{ showUpgrade }} cases left</span>
      </div>
      <div>
        <a
          @click.prevent="$router.push({ query: { ...this.$route.query, profile: 'plan' } })"
          :id="testInfo.upgradeItems"
          class="text-link uppercase"
        >Upgrade</a>
      </div>
    </div>

    <div
      v-if="menu.length"
      class="bg-white"
      :class="{ 'br-top-left-5 br-top-right-5': simpleMode || !showUpgrade }"
    >
      <ul
        :class="[ simpleMode ? 'px-12 py-10' : 'px-12 py-4' ]"
      >
        <SidebarMenuItem
          v-for="(item, index) in menuVisible"
          :key="item.rid"
          :index="index"
          :item="item"
          :editMode="editMode"
          :linkLimit="linkLimit"
          :lastItem="(index + 1) === menuLimitData"
          :itemRoute="itemRoute"
          :test-info="testInfo"
          :simple-mode="simpleMode"
        />
      </ul>
    </div>

    <div class="d-flex justify-between align-center bg-form px-12 py-12 br-bottom-left-5 br-bottom-right-5">
      <div>
        <span class="text-link color-text-lighter uppercase">{{ menuLimitData }}/{{ menuLength }}</span>
      </div>
      <div v-if="menuLimit < menuLength">
        <a
          @click.prevent="toggleShow"
          :id="testInfo.showAllItems"
          class="text-link uppercase"
        >
          {{ menuLimitData === menuLimit ? 'Show all' : 'Hide' }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarMenuItem from '@/components/sidebar/MenuItem';

export default {
  components: {
    SidebarMenuItem
  },
  props: {
    simpleMode: { default: false },
    menu: { default: [] },
    menuLimit: { default: 5 },
    testInfo: { default: {} },
    editMode: { default: false },
    linkLimit: { default: 26 },
    itemRoute: { default: '' },
    showUpgrade: { default: false }
  },
  data () {
    return {
      menuLimitData: this.menuLimit
    };
  },
  watch: {
    menuLength: {
      immediate: true,
      handler () {
        if (this.menuLimit > this.menuLength) this.menuLimitData = this.menuLength;
        else this.menuLimitData = this.menuLimit;
      }
    }
  },
  computed: {
    menuList () {
      let createdAt = 'created_at';
      if (this.menu[0]?.crtunix) createdAt = 'crtunix';
      const menu = this.menu;
      return menu.sort((a, b) => b[createdAt] - a[createdAt]);
    },
    menuLength () {
      return this.menu.length;
    },
    menuVisible () {
      return this.menuList.slice(0, this.menuLimitData);
    }
  },
  methods: {
    toggleShow () {
      if (this.menuLength > this.menuLimitData) this.menuLimitData = this.menuLength;
      else this.menuLimitData = this.menuLimit;
    }
  }
};
</script>
