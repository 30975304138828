<template>
  <SidebarCaseTitle
    v-if="getCurrentCase"
    :title="getCurrentCase.tag"
  />
  <SidebarMenuTitle
    title="Profile search"
    :active-route="'/new-search/' + $route.params.id"
    icon="user-search"
    :show-plus="true"
    :test-info="{
      testTitle: 'sidebar-profile-results',
      testPlus: 'sidebar-add-search'
    }"
    @plus-action="newSearch"
  />
  <SidebarMenuList
    :simple-mode="true"
    :menu="getSearches"
    item-route="/case/{fid}/{rid}"
    menu-limit="3"
    link-limit="26"
    :test-info="{
      caseLink: 'test-sidebar-search',
      showAllItems: 'test-sidebar-search-all'
    }"
  />
  <SidebarMenuTitle
    v-if="getConnections.length"
    title="Connection search"
    icon="hierarchy"
    :show-plus="false"
    :test-info="{
      testTitle: '',
      testPlus: ''
    }"
    @plus-action="newSearch"
  />
  <SidebarMenuList
    :simple-mode="true"
    :menu="getConnections"
    item-route="/case/{case_id}/{search_id}/{id}"
    menu-limit="3"
    link-limit="26"
    :test-info="{
      caseLink: 'test-sidebar-relation',
      showAllItems: 'sidebar-more-connections'
    }"
  />
  <template v-if="!getSidebarLoader">
    <SidebarMenuTitle
      title="Saved Tags"
      icon="archive-tick"
      :active-route="'/tags/' + $route.params.id"
      :check-menu="true"
      :test-info="{
        caseLink: '',
        showAllItems: ''
      }"
    />
    <SidebarTagsList
      :tags="getCurrentTagged"
    />
    <SidebarAlert
      v-if="!getCurrentTagged.length"
      class="mb-18"
    >
      <template v-slot:title>Assign profiles to a tag</template>
      <template v-slot:text>
        Tags are one of the main tools of our service. With their help, you can find connections between profiles and generate a report.
      </template>
    </SidebarAlert>
  </template>
  <SidebarMenuTitle
    title="Report"
    icon="document-text"
    :active-route="'/report/' + $route.params.id"
    :test-info="{
      caseLink: '',
      showAllItems: ''
    }"
  />
</template>

<script>
import SidebarCaseTitle from '@/components/sidebar/CaseTitle';
import SidebarMenuTitle from '@/components/sidebar/MenuTitle';
import SidebarMenuList from '@/components/sidebar/MenuList';
import SidebarTagsList from '@/components/sidebar/TagsList';
import SidebarAlert from '@/components/sidebar/Alert';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    SidebarCaseTitle,
    SidebarMenuTitle,
    SidebarMenuList,
    SidebarTagsList,
    SidebarAlert
  },
  async created () {
    this.SET_SIDEBAR_LOADER(true);
    this.setCurrentCase();
    this.setCurrentSearches();
    this.setCurrentConnections();
    await this.setCurrentTagged();
    this.SET_SIDEBAR_LOADER(false);
  },
  computed: {
    ...mapGetters(['getCurrentCase', 'getSearches', 'getConnections', 'getCurrentTagged', 'getSidebarLoader'])
  },
  methods: {
    ...mapActions(['setCurrentCase', 'setCurrentSearches', 'setCurrentConnections', 'setCurrentTagged']),
    ...mapMutations(['SET_SIDEBAR_LOADER']),
    newSearch () {
      this.$router.push({ path: '/new-search/' + this.$route.params.id });
    }
  }
};
</script>
