<template>
  <div class="d-flex align-center jc-space-between py-4 mx-12">
    <label class="text-caption">{{ group.title }}</label>
    <Checkbox
      class="custom-checkbox"
      value="test"
      v-model="selectAllNodes"
      :binary="true"
      @change="checkAllNodes"
      v-if="!hideCheckboxes"
    />
  </div>
  <ProfileDetails
    class="mx-12"
    v-for="node in groupData.nodes"
    :key="node.oid"
    :node="node"
    :tag-id="tagId"
    :hide-checkbox="hideCheckboxes"
  />
</template>

<script>
import Checkbox from 'primevue/checkbox';
import ProfileDetails from '@/components/UI/ProfileDetails';
import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
  components: {
    Checkbox,
    ProfileDetails
  },
  props: ['group', 'tagId'],
  data () {
    return {
      selectAllNodes: false,
      groupData: this.group,
      checkedNodes: []
    };
  },
  watch: {
    getCheckedValues: {
      deep: true,
      immediate: true,
      handler () {
        const profile = this.groupData.nodes.every(node => {
          return this.getCheckedValues[node.oid];
        });

        if (profile) {
          this.selectAllNodes = true;
        } else {
          this.selectAllNodes = false;
        }
      }
    },
    group: {
      deep: true,
      handler () {
        this.groupData = this.group;
      }
    }
  },
  computed: {
    ...mapGetters(['getCheckedValues', 'getCurrentResults', 'hideCheckboxes'])
  },
  methods: {
    ...mapActions(['setUpdateResults']),
    ...mapMutations(['CHECK_ALL_ITEMS']),
    checkAllNodes () {
      const obj = {
        checked: this.selectAllNodes
      };
      this.groupData.nodes.forEach(node => {
        obj.item = node;
        this.CHECK_ALL_ITEMS(obj);
      });
    }
  }
};
</script>
