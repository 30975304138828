<template>
  <a
    @click.prevent="toActiveRoute"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    class="d-flex align-center jc-space-between fs-14 lh-24 fw-400"
    :class="[
      testInfo.caseLink,
      { 'b-bottom bc-form-border' : !lastItem && !simpleMode },
      [ simpleMode ? 'mt-2 mb-2' : 'pt-8 pb-8' ]
    ]"
  >
    <div
      v-if="!simpleMode"
      class="item-index mr-12"
      :class="{ 'is-active': hovered || editPanel }"
    >
      <span v-show="!hoverPanel && !editPanel">{{ index + 1 }}</span>
      <div
        v-if="hoverPanel && !editPanel"
        class="d-flex align-center"
      >
        <button
          @click.stop.prevent="openPanel"
          :class="testInfo.editPanel"
        >
          <Icon
            name="edit-viewbox-right"
            class="icon-small icon-simple"
          />
        </button>
        <button
          @click.stop.prevent="setCaseToRemove(item)"
          :class="testInfo.deleteItem"
        >
          <Icon name="trash-viewbox-left" class="icon-small icon-simple" />
        </button>
      </div>
      <div v-if="editPanel">
        <button
          @click.stop.prevent="editPanel = false"
          class="d-flex align-center jc-center h-20px w-100"
          :class="testInfo.editPanel"
        >
          <Icon name="arrow-left" class="py-2 mr-2" />
        </button>
      </div>
    </div>
    <div
      v-if="editPanel"
      @click.stop.prevent
      class="edit-panel"
    >
      <input
        ref="input"
        v-model="caseName"
        @blur="editItem"
        @keydown.enter="editItem"
        type="text"
        class="fw-600"
        :class="testInfo.renameItem"
      />
      <div class="edit-panel__text">
        <span :class="{ 'color-warning' : overLimit }">{{ caseName.length }}</span> / {{ linkLimit }} would be visible
      </div>
    </div>
    <div
      v-else
      class="d-flex align-center jc-space-between w-100"
    >
      <Tooltip
        :text="itemTitleToggle"
        :limit="linkLimit"
        :className="{
          'color-link' : hovered || activeLink
        }"
      />
      <div
        v-show="(!hovered && simpleMode) || !simpleMode"
        class="text-caption text-overflow"
        :class="{ 'color-link': hovered || activeLink }"
      >
        {{ date(itemDateToggle) }}
      </div>
      <div v-if="hovered && simpleMode">
        <button
          class="text-link"
          @click.stop="removeItem"
        >
          <Icon name="trash-no-fill" />
        </button>
      </div>
    </div>
  </a>
</template>

<script>
import Icon from '@/components/app/Icon';
import Tooltip from '@/components/UI/Tooltip';
import { dateFormat } from '@/utils/helpers';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Icon,
    Tooltip
  },
  data () {
    return {
      hovered: false,
      editPanel: false,
      showTooltip: false,
      caseName: ''
    };
  },
  props: ['item', 'index', 'editMode', 'linkLimit', 'lastItem', 'itemRoute', 'testInfo', 'simpleMode'],
  computed: {
    ...mapGetters(['getInvestigations']),

    hoverPanel () {
      if (!this.editMode) return false;
      else return this.hovered;
    },
    itemTitleToggle () {
      if (this.item.tag) return this.item.tag;
      else {
        const itemTitle = this.item.title.replace('{middle}', ' & ').replace('{end}', "'s connections");
        return itemTitle;
      }
    },
    itemDateToggle () {
      if (this.item.crtunix) return this.item.crtunix;
      else return this.item.created_at;
    },
    itemTitle () {
      if (this.itemTitleToggle.length > this.linkLimit) {
        return this.itemTitleToggle.substring(0, this.linkLimit) + '...';
      } else {
        return this.itemTitleToggle;
      }
    },
    overLimit () {
      return this.caseName.length > this.linkLimit;
    },
    activeRoute () {
      let itemRoute = '';
      if (this.itemRoute) {
        itemRoute = this.itemRoute;
        const matches = this.itemRoute.match(/\{[^}]+}/g);
        matches.forEach(m => {
          const match = m.replace('{', '').replace('}', '');
          itemRoute = itemRoute.replace(m, this.item[match]);
        });
      }
      return itemRoute;
    },
    activeLink () {
      return this.$route.path === this.activeRoute;
    }
  },
  methods: {
    ...mapActions(['setCaseToRemove', 'setEditCase', 'setRemoveSearch', 'setRemoveConnection', 'setLoaderBig']),

    date (val) {
      return dateFormat(val);
    },
    openPanel () {
      this.caseName = this.itemTitleToggle;
      this.editPanel = true;
      setTimeout(() => { this.$refs.input.focus(); }, 0);
    },
    editItem () {
      const caseInfo = {
        fid: this.item.oid,
        tag: this.caseName,
        oldTag: this.item.tag
      };
      this.setEditCase(caseInfo);
      this.editPanel = false;
    },
    toActiveRoute () {
      this.$router.push(this.activeRoute);
    },
    async removeSearch () {
      const fid = this.item.fid;
      this.$router.push(`/new-search/${fid}`);
      await this.setRemoveSearch(this.item);
      this.setLoaderBig(false);
    },
    async removeConnection () {
      if (this.activeLink) this.$router.push(`/case/${this.item.case_id}/${this.item.search_id}`);
      await this.setRemoveConnection(this.item);
      this.setLoaderBig(false);
    },
    removeItem () {
      this.setLoaderBig(true);
      if (this.item?.fid && this.item?.rid) this.removeSearch();
      else this.removeConnection();
    }
  }
};
</script>
