<template>
  <div class="d-flex align-center jc-space-between mb-26">
    <Tooltip
      :text="title"
      :limit="13"
      position-y="bottom"
      className="text-h3"
    />
    <router-link
      @mouseover="showTooltip = true"
      @mouseleave="showTooltip = false"
      to="/"
      id="main-back"
      class="bttn-white br-30 b-none h-35px"
    >
      <Icon name="arrow-left" />
      <span class="ml-10">Back to cases</span>
    </router-link>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon.vue';
import Tooltip from '@/components/UI/Tooltip';

export default {
  components: {
    Icon,
    Tooltip
  },
  data () {
    return {
      showTooltip: false
    };
  },
  props: {
    title: {
      default: ''
    },
    limitTitle: {
      default: 16
    }
  },
  computed: {
    titleShort () {
      if (this.title.length > this.limitTitle) {
        return this.title.substring(0, this.limitTitle) + '...';
      } else {
        return this.title;
      }
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1);
    }
  }
};
</script>
