<template>
  <div
    v-if="tag.nodes.length"
    class="b-bottom bc-form-border"
  >
    <a
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
      @click.prevent="opened = !opened"
      class="d-flex align-center jc-space-between py-12 mx-12"
    >
      <div
        class="d-flex align-center"
        :class="{ 'w-100': editPanel }"
      >
        <div
          v-show="hovered && !editPanel"
          class="item-index is-active d-flex align-center mr-8"
        >
          <button @click.stop.prevent="openPanel">
            <Icon
              name="edit-viewbox-right"
              class="icon-small icon-simple"
            />
          </button>
          <button @click.stop.prevent="removeItem">
            <Icon name="trash-viewbox-left" class="icon-small icon-simple" />
          </button>
        </div>
        <div
          v-show="editPanel"
          class="item-index is-active"
        >
          <button
            @click.stop.prevent="editPanel = false"
            class="d-flex align-center jc-center h-20px w-100"
          >
            <Icon name="arrow-left" class="py-2 mr-2" />
          </button>
        </div>
        <div v-show="!hovered && !editPanel" class="item-tag is-tag mr-8">tag</div>
        <Tooltip
          v-if="!editPanel"
          :text="tag.name"
          :limit="16"
          :className="{
            'menu-text fw-600': !hovered,
            'menu-text fw-600 color-link': hovered
          }"
        />
        <div
          v-else
          @click.stop.prevent
          class="edit-panel ml-8"
        >
          <input
            v-model="tagName"
            @blur="editItem"
            @keydown.enter="editItem"
            ref="input"
            type="text"
            class="fw-600"
          />
          <div class="edit-panel__text">
            <span :class="{ 'color-warning' : overLimit }">{{ tagName.length }}</span> / {{ linkLimit }} would be visible
          </div>
        </div>
      </div>
      <div
        v-if="!editPanel"
        class="menu-text fw-400"
      >
        <span class="color-text-lighter mr-10">({{ tag.nodes.length }} {{ tag.nodes.length > 1 ? 'entities' : 'entity' }})</span>
        <Icon
          name="arrow-down"
          class="color-link"
          :class="{ 'rotate--180': opened }"
        />
      </div>
    </a>
    <div
      v-show="opened"
      class="pb-8"
    >
      <TagsItemGroup
        v-for="group in nodes"
        :key="group.title"
        :group="group"
        :tag-id="tag.oid"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';
import TagsItemGroup from '@/components/sidebar/TagsItemGroup';
import { mapActions } from 'vuex';
import Tooltip from '@/components/UI/Tooltip';

export default {
  components: {
    Icon,
    Tooltip,
    TagsItemGroup
  },
  props: ['tag'],
  data () {
    return {
      opened: false,
      hovered: false,
      editPanel: false,
      tagName: '',
      linkLimit: 20,
      showTooltip: false
    };
  },
  computed: {
    nodes () {
      const nodes = [];
      const profiles = this.tag.nodes.filter(node => !node?.fields?.relation);
      const relations = this.tag.nodes.filter(node => node?.fields?.relation);
      if (profiles.length) nodes.push({ title: 'Profiles', nodes: profiles });
      if (relations.length) nodes.push({ title: 'Connections', nodes: relations });
      return nodes;
    },
    overLimit () {
      return this.tagName.length > this.linkLimit;
    }
  },
  methods: {
    ...mapActions(['setRenameTagged', 'setRemoveTagged']),

    openPanel () {
      this.tagName = this.tag.name;
      this.editPanel = true;
      setTimeout(() => { this.$refs.input.focus(); }, 0);
    },
    editItem () {
      const payload = { name: this.tag.name, tag: { id: this.tag.oid, name: this.tagName } };
      this.setRenameTagged(payload);
      this.editPanel = false;
    },
    removeItem () {
      const payload = { id: this.tag.oid, nodes: this.tag.nodes };
      this.setRemoveTagged(payload);
    }
  }
};
</script>
