<template>
  <Checkbox
    class="custom-checkbox"
    v-model="valueReal"
    :binary="true"
    @change="checkResult"
  />
</template>
<script>
import Checkbox from 'primevue/checkbox';
import { mapMutations, mapGetters } from 'vuex';
export default {
  components: {
    Checkbox
  },
  data () {
    return {
      valueReal: false
    };
  },
  props: ['profile'],
  watch: {
    valuePseudo: {
      immediate: true,
      handler () {
        if (this.getCheckedValues[this.profile.oid]) this.valueReal = true;
        else this.valueReal = false;
      }
    }
  },
  computed: {
    ...mapGetters(['getCheckedValues']),
    valuePseudo () {
      return this.getCheckedValues[this.profile.oid] || false;
    }
  },
  methods: {
    ...mapMutations(['ADD_OR_REMOVE_CHECKED_ITEM']),
    checkResult () {
      this.ADD_OR_REMOVE_CHECKED_ITEM(this.profile);
    }
  }
};
</script>
