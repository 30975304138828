<template>
  <div class="layout-main grid-layout pt-80">
    <div>
      <div class="layout-sidebar p-fixed z-1003 top-0 left-0 bottom-0 bg-form custom-scrollbar pr-0">
        <div class="p-sticky top-0 z-3">
          <SidebarHeader />
        </div>
        <div class="p-24">
          <slot name="sidebar"></slot>
        </div>
      </div>
    </div>
    <div class="p-48">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import SidebarHeader from '@/components/SidebarHeader';

export default {
  components: {
    SidebarHeader
  }
};
</script>
