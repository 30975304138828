<template>
  <div
    @mouseenter="hoverNode(true, 'in')"
    @mouseleave="hoverNode(true, 'out')"
    @click="filterResults"
    class="profile-details"
    :class="{ 'big': big, 'first': firstElem, 'with-checkbox': !hideCheckbox }"
  >
    <div class="d-flex align-center">
      <div
        @mouseenter="hoverNode(false, 'in')"
        @mouseleave="hoverNode(false, 'out')"
        class="panel"
        :class="{ 'hovered': hovered }"
      >
        <div class="icon-social">
          <Icon
            v-if="socialNetworkIcon"
            :name="socialNetworkIcon"
          />
        </div>
        <template v-if="node?.fields?.relation">
          <div
            v-show="hoveredBig"
            class="w-16px mr-6"
          >
            <button
              @click.stop.prevent="detachProfile"
            >
              <Icon name="trash-fill-small" />
            </button>
          </div>
          <Avatar
            class="icon-avatar margin"
            :src="avatarParent"
          />
          <div class="d-flex align-center mr-3">
            <Icon
              class="d-block"
              name="arrange-small"
            />
          </div>
          <Avatar
            class="icon-avatar"
            :src="avatarChild"
          />
          <span
            v-show="hovered"
            @click.stop="setOpenRightSidebar([node.childProfile, node.parentProfile])"
            class="text-caption cursor-pointer color-link color-link-lighter_hover transition ml-8">connection Details</span>
        </template>
        <template v-else>
          <Avatar
            v-show="!hoveredBig"
            class="icon-avatar margin"
            :src="avatar"
          />
          <button
            v-show="hoveredBig"
            @click.stop.prevent="detachProfile"
            class="mr-3"
          >
            <Icon name="trash-fill-small" />
          </button>
          <div class="d-flex align-center mr-3">
            <Icon
              class="d-block"
              name="brick-icon-small-done"
            />
            <span
              v-show="hovered"
              @click.stop="checkProfile"
              class="text-caption cursor-pointer color-link color-link-lighter_hover transition ml-4">profile Details</span>
          </div>
          <div
            class="d-flex align-center"
            :class="{ 'ml-10': hovered }"
          >
            <Icon
              class="d-block"
              name="brick-icon-small"
            />
            <span
              v-show="hovered"
              @click.stop="setOpenRightSidebar([ node, 'openSocial' ])"
              class="text-caption cursor-pointer color-text-lighter color-link-lighter_hover transition ml-4">Social graph</span>
          </div>
        </template>
      </div>
      <router-link
        v-show="!hoveredBig"
        :to="link"
        class="p-relative d-block ml-12"
        :class="{
          'd-block': !big,
          'd-flex align-center': big
        }"
      >
        <Tooltip
          @mouseenter="showTooltip = true"
          @mouseout="showTooltip = false"
          :text="name"
          :limit="20"
          :className="{
            'text': !showTooltip,
            'text hovered': showTooltip
          }"
        />
        <span v-if="big" class="text color-text-lighter">
          &nbsp;{{ location }}
        </span>
      </router-link>
    </div>
    <ProfileCheckbox v-if="!hideCheckbox" :profile="node" />
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';
import Avatar from '@/components/app/Avatar';
import ProfileCheckbox from '@/components/app/ProfileCheckbox';
import Tooltip from '@/components/UI/Tooltip';
import { getProfileLocation, getProfileName, getProfileAvatar } from '@/utils/helpers';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Icon,
    Avatar,
    Tooltip,
    ProfileCheckbox
  },
  props: {
    node: {
      default: []
    },
    textLimit: {
      default: 22
    },
    hideCheckbox: {
      default: false
    },
    big: {
      default: false
    },
    filter: {
      default: null
    },
    tagId: {
      default: null
    },
    firstElem: {
      default: false
    }
  },
  data () {
    return {
      hovered: false,
      test: false,
      showTooltip: false,
      nodeChecked: this.node.checked
    };
  },
  computed: {
    ...mapGetters(['getCurrentResults', 'getCurrentRelations']),

    socialNetworkIcon () {
      try {
        for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
          if (this.node.typeid.includes(socialNet)) {
            return 'person-card-' + socialNet.toLowerCase();
          }
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    name () {
      return getProfileName(this.node);
    },
    location () {
      return getProfileLocation(this.node);
    },
    avatar () {
      return getProfileAvatar(this.node);
    },
    avatarParent () {
      if (this.node?.parentProfile) return getProfileAvatar(this.node.parentProfile);
      else return '';
    },
    avatarChild () {
      if (this.node?.childProfile) return getProfileAvatar(this.node.childProfile);
      else return '';
    },
    hoveredBig () {
      if (this.big) return false;
      else return this.hovered;
    },
    link () {
      let caseId = '';
      if (this.node.cid) caseId = this.node.cid;
      else caseId = this.$route.params.id;

      if (this.node?.fields?.relation) return `/connection/${caseId}/${this.node.rid}/${this.node.rsid}/${this.node.oid}`;
      else return `/profile/${caseId}/${this.node.rid}/${this.node.oid}`;
    }
  },
  methods: {
    ...mapActions(['setOpenRightSidebar', 'setUpdateResults', 'setUpdateRelations', 'setRemoveTagged']),

    hoverNode (isBig, action) {
      if ((this.big && isBig) || (!this.big && !isBig)) {
        if (action === 'in') this.hovered = true;
        else this.hovered = false;
      }
    },
    filterResults () {
      if (this.filter && this.filter === 'profile') {
        const results = this.getCurrentResults;
        results.forEach(result => {
          if (this.node.oid === result.oid) result.visible = true;
          else result.visible = false;
        });
        this.setUpdateResults(results);
      } else
      if (this.filter && this.filter === 'connections') {
        const results = this.getCurrentRelations;
        results.forEach(result => {
          if (this.node.oid === result.childProfile.oid) result.visible = true;
          else result.visible = false;
        });
        this.setUpdateRelations(results);
      }
    },
    detachProfile () {
      const nodeInfo = { ...this.node, index: this.index };
      const payload = { id: this.tagId, nodes: [nodeInfo] };
      this.setRemoveTagged(payload);
    },
    checkProfile () {
      let node = this.node;
      if (this.getCurrentResults.filter(profile => this.node.oid === profile.oid).length) {
        node = this.getCurrentResults.filter(profile => this.node.oid === profile.oid)[0];
      }

      this.setOpenRightSidebar(node);
    }
  }
};
</script>
