<template>
  <div
    class="d-flex justify-between align-center w-100 pb-18"
    :id="[ testLink ? testInfo.testTitle : null ]"
  >
    <div
      @click="toActiveRoute"
      :id="[ !testLink ? testInfo.testTitle : null ]"
      class="d-flex align-center border-0 cursor-pointer"
      :class="{ 'color-link': activeLink, 'w-96px text-center fd-column': mobile }"
    >
      <div
        class="icon-wrap color-link"
        :class="{ 'mr-12': !mobile, 'mb-14': mobile }"
      >
        <Icon v-if="activeLink" :name="icon + '-fill'" />
        <Icon v-else :name="icon" />
      </div>
      <span
        class="lh-16 fw-600 uppercase"
        :class="{ 'color-black': !activeLink, 'fs-14': !mobile, 'fs-11 w-70': mobile }"
      >{{ title }}</span>
      <div
        v-if="beta && !mobile"
        class="br-4 bg-form-darker fs-11 uppercase fw-600 px-8 py-2 ml-12"
        :class="{ 'color-text-lighter' : !activeLink, 'color-link' : activeLink }"
      >beta</div>
    </div>
    <a
      v-if="showPlus && !mobile"
      :id="[ !testLink ? testInfo.testPlus : null ]"
      class="px-6"
      @click.prevent="$emit('plusAction')"
    ><Icon name="plus" /></a>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';
export default {
  props: {
    title: { default: '' },
    activeRoute: { default: null },
    icon: { default: null },
    beta: { default: false },
    showPlus: { default: false },
    checkMenu: { default: false },
    testInfo: { default: null },
    mobile: { default: false }
  },
  emits: ['plusAction'],
  components: { Icon },
  computed: {
    activeLink () {
      return this.$route.path === this.activeRoute;
    },
    testLink () {
      return this.testInfo.testTitle === this.testInfo.testPlus;
    }
  },
  methods: {
    toActiveRoute () {
      if (this.checkMenu && this.checkMenu.length === 0) this.$emit('plusAction');
      else this.$router.push(this.activeRoute);
    }
  }
};
</script>
