<template>
  <div
    v-if="tags.length"
    class="bs-controller br-5 mb-26"
  >
    <!-- <div class="d-flex justify-between align-center bg-form px-12 py-12 br-top-left-5 br-top-right-5">
      <div class="text-caption">
        <span class="color-text-lighter">13/20 entities left</span>
        &nbsp;
        <a class="color-link" href="">Upgrade</a>
      </div>
      <div
        @click.prevent="opened = !opened"
        class="text-caption cursor-pointer"
      >
        <span class="color-text-lighter mr-10">All</span>
        <Icon
          name="arrow-down"
          class="color-link"
          :class="{ 'rotate--180': opened }"
        />
      </div>
    </div> -->
    <div
      v-show="opened"
      class="bg-white br-top-left-5 br-top-right-5"
    >
      <SidebarTagsItem
        v-for="tag in tagsList"
        :key="tag.oid"
        :tag="tag"
      />
    </div>

    <div class="d-flex justify-between align-center bg-form px-12 py-12 br-bottom-left-5 br-bottom-right-5">
      <div class="text-caption color-text-lighter">
        {{ tagsList.length }}/{{ tags.length }}
      </div>
      <div
        v-if="tags.length > limit"
        class="text-caption color-link"
      >
        <a @click.prevent="showAllTags">
          {{ showAllText }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarTagsItem from '@/components/sidebar/TagsItem';

export default {
  components: {
    SidebarTagsItem
  },
  props: {
    tags: {
      default: []
    }
  },
  data () {
    return {
      opened: true,
      limit: 3,
      showAllText: 'Show all'
    };
  },
  computed: {
    tagsList () {
      if (this.limit) return this.tags.slice(0, this.limit);
      else return this.tags;
    }
  },
  methods: {
    showAllTags () {
      if (this.limit) {
        this.limit = null;
        this.showAllText = 'Hide';
      } else {
        this.limit = 3;
        this.showAllText = 'Show all';
      }
    }
  }
};
</script>
